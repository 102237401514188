// this is the page after you select a pack and choose payment method
import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  ButtonBase,
  Button,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Alert from "@mui/material/Alert";
import Sobflous from "../../../assets/images/sobflous.png";
import EDINARLogo from "../../../assets/images/paiment/CB-EDINAR.png";
import flouciLogo from "../../../assets/images/flouciLogo.png";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { createCredit, setResponsePayload } from "../../../redux/userSlice";
import { createPurchase } from "../../../redux/packPurchaseSlice";
import { GrFormClose } from "react-icons/gr";
import axios from "axios";
import { autoSelectPack } from "../../../redux/packUseSlice";
import { useParams } from "react-router-dom";

const PackModalMethodPay = (props) => {
  const [firstUrl, setFirstUrl] = useState("");
  const [orderId, setOrderId] = useState("");
  const [userName, setUserName] = useState("2812800148");
  const [password, setPassword] = useState("he5wD7B6");
  const [statusMessage, setStatusMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [popupWindow, setPopupWindow] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(true);
  const [alertVerifFlouci, setAlertVerifFlouci] = useState();

  // get room id from URL
  const params = useParams();
  const roomId = params.id;

  // handle alert close
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  // get (user) data from redux-toolkit
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const usernameClient = useSelector((state) => state.user.userInfo.username);

  // get (packs) data from redux-toolkit extract the prix, validite, credit and image
  const packs = useSelector((state) => state.pack.Packs);
  let prix, validite, credit, image;
  for (let index = 0; index < packs.Packs.length; index++) {
    const element = packs.Packs[index];
    let packId = props.selectedPack;
    if (packId === element._id) {
      prix = element.prix;
      validite = element.num_uses;
      credit = element.credit;
      image = element.image.imageURL;
    }
  }

  // function to generate pack id using uuidv4 library
  const generateOrderNumber = () => {
    const orderNumber = uuidv4().replace(/-/g, "");
    return orderNumber.substring(0, 32);
  };
  
  const getKonnectPaymentLink = async (amount, usernameClient, credit, orderId) => {
    
    let konnectData = await axios.post("https://api.konnect.network/api/v2/payments/init-payment", {
      "receiverWalletId": "6683eb4254de7c9e540c0d1a",
      "orderId": orderId,
      "token": "TND",
      "amount": amount,
      "firstName": userInfo?.firstName,
      "lastName":  userInfo?.lastName,
      "successUrl": "https://soomy.tn/windowPopup",
      "description" : `Achat pack ${credit} Bitso, client: ${usernameClient}`
    },{
      headers: {
        "x-api-key": "6683eb4254de7c9e540c0d14:cwmiMZFRCL9xSIAX9uGjayc7icp"
      }
    });

    return konnectData.data;
  }
  // handle payment click
  const handleCardClickToPay = async () => {
    const orderNumber = generateOrderNumber();
    const amount = prix * 1000;
    let konnectData = await getKonnectPaymentLink(amount, usernameClient, credit, orderNumber);

    //window popup configs css
    const height = window.innerHeight; //height of the popup window payment
    const width = 390; //Width of the popup window payment
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const features = `width=${width},height=${height},top=${top},left=${left}`;

    const popupWindow = window.open("", "", features);

    setIsLoading(true); // the backdrop and spinner loader (true = open)
    const receivedFormUrl = konnectData.payUrl;
    const orderId = konnectData.paymentRef; // get orderId
    setOrderId(orderId);

        if (receivedFormUrl) {
          setPopupWindow(popupWindow);
          setFirstUrl(receivedFormUrl);
          popupWindow.location = receivedFormUrl;
          popupWindow.focus();

          // check if popup is closed or not, if closed, will launch verifyOperation to verify if payment is success or fail
          const checkPopupClosed = setInterval(() => {
            if (popupWindow.closed) {
              clearInterval(checkPopupClosed);
              if (orderId) {
                launchVerifyOperation(orderId); // launch verifyOperation with extracted (from clicToPay server) OrderId
              }
            }
          }, 1000);
        }
  };

  // flouci bouton
  const handleCardClick = async () => {
    //window popup configs css
    const height = window.innerHeight; //height of the popup window payment
    const width = 390; //Width of the popup window payment
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const features = `width=${width},height=${height},top=${top},left=${left}`;

    const popupWindow = window.open("", "", features);

    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://soomy.tn/api/generatePayment",
        {
          amount: prix * 1000,
        }
      );

      const link = response.data.link;
      const payment_id = response.data.payment_id;

      if (link) {
        setPopupWindow(popupWindow);
        setFirstUrl(link);
        popupWindow.location = receivedFormUrl;
        popupWindow.focus();

        // check if popup is closed or not, if closed, will launch verifyOperation to verify if payment is success or fail
        const checkPopupClosed = setInterval(async () => {
          if (popupWindow.closed) {
            clearInterval(checkPopupClosed);
            if (payment_id) {
              // launch verification with extracted (from flouci server) payment_id
              try {
                const response = await axios
                  .post("https://soomy.tn/api/verifyFlouciPayment", {
                    payment_id: payment_id,
                  })
                  .then(setIsLoading(false));

                if (response.result.status === "FAILURE") {
                  setAlertVerifFlouci(false);
                } else {
                  setAlertVerifFlouci(true);
                }
              } catch (error) {
                setIsLoading(false); // close popup
                console.error(
                  "Error:",
                  error.message || "Erreur de serveur interne"
                );
              }
            }
          }
        }, 1000);
      }
    } catch (error) {
      console.error("Error:", error.message || "Erreur de serveur interne");
    }
  };

  const launchVerifyOperation = (orderId) => {
    const url = `https://api.konnect.network/api/v2/payments/${orderId}/`;

    const orderStatusMessages = {
      0: "Paiement non éffectué.",
      1: "Paiement non éffectué.",
      2: "Paiement effectué avec succès.",
      3: "Paiement non éffectué.",
      4: "Paiement non éffectué.",
      5: "Paiement non éffectué.",
      6: "Paiement non éffectué.",
    };

    // verif if payment is success or fail with clicToPay server
    fetch(url)
      .then((response) => response.json())
      .then((data) => {

        const orderStatus = data.payment.status;
        let severity = "success";
        if (orderStatus != "completed") {
          severity = "warning";
        }

        setStatusMessage(orderStatus != "completed" ? "Paiement non éffectué." : "Paiement effectué avec succès.");

        setSeverity(severity); // set alert type "warning" or "success"
        setIsLoading(false); // close backdrop
        
        // in case success will dispatch to redux-toolkit (and then to axios) the next payload (type, user, service, montant, status, ref)
        if (orderStatus == "completed") {
          // create transaction
          dispatch(
            createCredit({
              type: `Pack Bitso ${credit}`,
              user: userInfo._id,
              service: "ClicToPay",
              montant: prix,
              status: "COMPLETED",
              ref: orderId,
              transactionDate: Date.now(),
            })
          )
            .then((data) => {
              if (data.payload.status === "COMPLETED") {
                // if response data is "COMPLETED"
                setStatusMessage(data.payload.message); // set alert message (statusMessage)
                dispatch(setResponsePayload(data.payload)); //dispatch (responsePayload) to redux-toolkit

                // dispatch for createPurchase pack
                let packId = props.selectedPack;
                dispatch(
                  createPurchase({
                    pack_id: packId,
                    user_id: userInfo._id,
                    transaction_id: data.payload.transaction_id,
                  })
                );
              }
            })
            .then(() => {
              // then run auto-select-pack API
              dispatch(autoSelectPack({ roomId, user_id: userInfo?._id }));
            });
        }
      })
      .catch((error) => {
        // if error, ...
        setIsLoading(false); // close backdrop
        setStatusMessage("Error: ", error); // set error as alert message
      });
  };

  // handle to close popup
  const handleClickClosePopup = () => {
    if (popupWindow) {
      popupWindow.close(); // close popup
      setPopupWindow(null);
    }
    setIsLoading(false); // close backdrop
  };

  // the previously dispatched responsePayload will be selected from state
  const responsePayload = useSelector((state) => state.user.responsePayload);

  useEffect(() => {
    let timeoutId;

    if (responsePayload || responsePayload !== null) {
      // if there is a responsePayload
      timeoutId = setTimeout(() => {
        dispatch(setResponsePayload(null)); // remove responsePayload after 6 seconds (= remove success alert after 6 seconds automatically)
      }, 6000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [dispatch, statusMessage, responsePayload]);

  return (
    <>
      {isLoading ? (
        <Backdrop open={isLoading}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress
              style={{ color: "#F9124E" }}
              thickness={7}
              size={80}
            />
            <h1 style={{ color: "#ffffff" }}>
              Traitement de l'achat, veuillez patienter
            </h1>
            <Button
              style={{ color: "#ffffff" }}
              onClick={handleClickClosePopup}
            >
              Annuler Achat
            </Button>
          </div>
        </Backdrop>
      ) : (
        <>
          {statusMessage && !responsePayload && showAlert && (
            <Alert severity={severity} style={{ marginBottom: "10px" }}>
              <div
                class="inner-alert"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span>{statusMessage}</span>
                <div style={{ marginLeft: "78px" }}>
                  <GrFormClose
                    style={{ cursor: "pointer" }}
                    onClick={handleCloseAlert}
                  />
                </div>
              </div>
            </Alert>
          )}
          {!statusMessage && responsePayload && showAlert && (
            <Alert
              severity={responsePayload.severity}
              style={{ marginBottom: "10px" }}
            >
              <div
                class="inner-alert"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span>{responsePayload.message}</span>
                <div style={{ marginLeft: "78px" }}>
                  <GrFormClose
                    style={{ cursor: "pointer" }}
                    onClick={handleCloseAlert}
                  />
                </div>
              </div>
            </Alert>
          )}
          {statusMessage && responsePayload && showAlert && (
            <Alert severity={severity} style={{ marginBottom: "10px" }}>
              <div
                class="inner-alert"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span>{statusMessage}</span>
                <div style={{ marginLeft: "78px" }}>
                  <GrFormClose
                    style={{ cursor: "pointer" }}
                    onClick={handleCloseAlert}
                  />
                </div>
              </div>
            </Alert>
          )}
          {/*           
          {alertVerifFlouci && <p>success</p>}

          {!alertVerifFlouci && <p>erreur</p>}
 */}
          <h1>Pack sélectionné:</h1>
          <div
            className="selectionned-pack"
            style={{ justifyContent: "center" }}
          >
            <Card sx={{ maxWidth: 130, marginBottom: "10px" }}>
              <CardMedia
                component="img"
                alt={credit + " Bitso"}
                height="140"
                image={image}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="pack-description"
                >
                  Valable pour <span>{validite}</span> enchéres
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="pack-price"
                >
                  <p className="pack-price-text">
                    <span>{prix}</span> Dinars
                  </p>
                </Typography>
              </CardContent>
            </Card>
          </div>

          <h5 style={{ fontWeight: "600" }}>Choisir le moyen de paiement:</h5>

          <div
            className="method-payment-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ButtonBase
              sx={{ maxWidth: 200, maxHeight: 260, cursor: "pointer" }}
              onClick={handleCardClickToPay}
            >
              <Card>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="carte-bancaire-text"
                  >
                    Payez en ligne
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <p style={{ fontWeight: "500" }}>
                      Cartes Bancaires / E-dinar
                    </p>
                  </Typography>
                  <CardMedia
                    className="EdinarLogo"
                    component="img"
                    alt="ClicToPay logo"
                    image={EDINARLogo}
                  />
                </CardContent>
              </Card>
            </ButtonBase>

            {/* <ButtonBase
              sx={{
                maxWidth: 200,
                maxHeight: 260,
                cursor: "pointer",
                marginLeft: "5px",
              }}
              onClick={handleCardClick}
            >
              <Card>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="carte-bancaire-text"
                  >
                    Carte / E-Dinar
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Payez avec:
                  </Typography>
                  <CardMedia
                    className="clicToPay-img"
                    component="img"
                    alt="ClicToPay logo"
                    image={flouciLogo}
                  />
                </CardContent>
              </Card>
            </ButtonBase> */}

            {/* <span
              style={{
                fontFamily: "revansBold",
                fontWeight: "bold",
                fontSize: "x-large",
                margin: "5px",
              }}
            >
              OU
            </span>
            <ButtonBase
              sx={{ maxWidth: 200, maxHeight: 260, cursor: "pointer" }}
              onClick={() => props.setService("Carte bancaire")}
            >
              <Card
                sx={{ maxWidth: 200, maxHeight: 260, cursor: "pointer" }}
                onClick={() => props.setService("Paypal")}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="carte-bancaire-text"
                  >
                    <span style={{ visibility: "hidden" }}>Carte Bancaire</span>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Payez avec:
                  </Typography>
                  <CardMedia
                    className="sobflous-img"
                    component="img"
                    alt="Sobflous logo"
                    image={Sobflous}
                  />
                </CardContent>
              </Card>
            </ButtonBase> */}
          </div>
        </>
      )}
    </>
  );
};

export default PackModalMethodPay;
