import React, { useState, useEffect } from "react";
import Footer from "../../../components/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { updateUsername } from "../../../redux/userSlice";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";

const ChangeUsername = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);
  const error = useSelector((state) => state.user.updateErrors);
  const loading = useSelector((state) => state.user.updateLoading);

  const [newUsername, setNewUsername] = useState(userInfo?.username);

  const handleUsernameChange = (e) => {
    setNewUsername(e.target.value);
  };

  const navigate = useNavigate();

  const handleUpdateUsername = async (e) => {
    e.preventDefault();

    // Check if the new username is different from the current username
    if (newUsername !== userInfo.username) {
      // Dispatch the updateUsername action with the new username
      await dispatch(updateUsername({ idUser: userInfo._id, newUsername }));

      // store in localstorage boolean value for changed
      localStorage.setItem("Uchan", true);
      // After the update is complete, navigate to the home page
      navigate("/");
    }
  };

  const handleCancel = async () => {
    // Reset newUsername to its default value (userInfo?.username)
    setNewUsername(userInfo?.username);
    // Dispatch the updateUsername action even when "Annuler" is clicked
    await dispatch(
      updateUsername({ idUser: userInfo._id, newUsername: userInfo?.username })
    );
    // After the dispatch is complete, navigate to the home page
    navigate("/");
  };

  // redirect client to home if not google user or isUserChanged true 
  useEffect(() => {
    if (!userInfo?.googleId || userInfo?.isUserChanged === true) {
      navigate("/");
    }
  }, [userInfo]);

  return (
    <div>
      <main className="d-flex flex-column justify-content-center align-items-center register p-lg-5 ">
        <div className="container p-4 edge-shadow-connect  d-flex justify-content-center align-items-center flex-column">
          <section className="d-flex justify-content-between my-3 auction-filter px-3">
            <form
              className={"row g-3 mt-4 mx-lg-5 mx-auto px-2 "}
              onSubmit={(e) => handleUpdateUsername(e)}
            >
              <div className=" container pt-2 px-lg-0 pb-1">
                <section className="page-title page-title-auction mb-4">
                  <h1>Nom d'utilisateur</h1>
                  <h3>Veuillez modifier votre nom d'utilisateur</h3>
                </section>
                <input
                  type="text"
                  className="form-control"
                  name="username"
                  id="validationServerUsername"
                  aria-describedby="inputGroupPrepend3 validationServerUsernameFeedback"
                  onChange={(e) => handleUsernameChange(e)}
                  placeholder="Votre nom d'utilisateur"
                  value={newUsername}
                  style={{
                    backgroundColor: "white",
                    marginRight: "1.5em",
                  }}
                />
                {error ? (
                  <Alert
                    className="mt-2"
                    severity="error"
                    style={{ marginLeft: "1.5em", marginRight: "1.5em" }}
                  >
                    {error}
                  </Alert>
                ) : null}
              </div>
              <div className="col-12 d-flex justify-content-center align-items-center">
                <button
                  className={`btn btn-cancel my-3 w-auto`}
                  type="button"
                  onClick={handleCancel}
                >
                  Annuler
                </button>
                <button
                  className={`btn btn-confirm my-3 w-auto${
                    (loading || !newUsername || newUsername === userInfo.username) ? " btn-disabled" : ""
                  }`}
                  type="submit"
                  style={{
                    marginLeft: "1em",
                    cursor: loading ? "wait" : "pointer",
                  }}
                  disabled={loading || !newUsername || newUsername === userInfo.username}
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Chargement
                    </>
                  ) : (
                    "Confirmer"
                  )}
                </button>
              </div>
            </form>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ChangeUsername;
