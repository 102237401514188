import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const createSlider = createAsyncThunk(
  "Slider/createSlider",
  async (
    { sliderData, selectedImage, selectedImage1 },
    { rejectWithValue, dispatch }
  ) => {
    console.log(sliderData);
    console.log("selectedImage: ", selectedImage);
    console.log("selectedImage1: ", selectedImage1);
    const formData = new FormData();
    formData.append("image", selectedImage);
    formData.append("image", selectedImage1);
    formData.append("link", sliderData.link);
    formData.append("status", sliderData.status);
    formData.append("order", sliderData.order);
    try {
      console.log("Redux createSlider - formData: ", formData);
      const res = await axios.post("/slider/addSliderImage", formData, {
        withCredentials: true,
      });
      dispatch(getAllSliders());
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.errors.password.msg
      );
    }
  }
);

export const getAllSliders = createAsyncThunk(
  "Slider/getAllSliders",
  async (info, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/slider/getSliderImages`, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.errors.password.msg
      );
    }
  }
);

// export const getSliderById = createAsyncThunk(
//   "Pack/getTotalPacks",
//   async (info, { rejectWithValue }) => {
//     try {
//       const res = await axios.get(`/Packs/getTotalPacks`, {
//         withCredentials: true,
//       });
//       return res.data;
//     } catch (error) {
//       return rejectWithValue(
//         error.response.data.message
//           ? error.response.data.message
//           : error.response.data.errors.password.msg
//       );
//     }
//   }
// );

export const updateSlider = createAsyncThunk(
  "Slider/updateSlider",
  async (info, { rejectWithValue, dispatch }) => {
    console.log("redux - info: ", info);
    try {
      const res = await axios.put(
        `/slider/updateSliderImage/${info.id}`,
        info.data,
        {
          withCredentials: true,
        }
      );
      dispatch(getAllSliders());
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateSliderDesktopImage = createAsyncThunk(
  "Slider/updateSliderDesktopImage",
  async (info, { rejectWithValue, dispatch }) => {
    const formData = new FormData();
    formData.append("image", info.file);
    try {
      const res = await axios.put(
        `/slider/updateSliderDesktopImage/${info.id}`,
        formData,
        {
          withCredentials: true,
        }
      );
      // dispatch(getAllSliders());
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.errors.password.msg
      );
    }
  }
);

export const updateSliderMobileImage = createAsyncThunk(
  "Slider/updateSliderMobileImage",
  async (info, { rejectWithValue, dispatch }) => {
    const formData = new FormData();
    formData.append("image", info.file);
    try {
      const res = await axios.put(
        `/Slider/updateSliderMobileImage/${info.id}`,
        formData,
        {
          withCredentials: true,
        }
      );
      // dispatch(getAllSliders());
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.errors.password.msg
      );
    }
  }
);

export const deleteSliderImageById = createAsyncThunk(
  "Slider/deleteSliderImageById",
  async (info, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.delete(
        `/slider/deleteSliderImageById/${info.id}`,
        {
          withCredentials: true,
        }
      );
      // dispatch(fetchPackList({ page: info?.page }));
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.errors.password.msg
      );
    }
  }
);

const SliderSlice = createSlice({
  name: "Slider",
  initialState: {
    getAllSlidersImages: null,
    getAllSlidersImagesLoading: false,
    getAllSlidersImagesError: null,
    getSlidersImagesById: null,
    addSlider: null,
    updateSlider: null,
    createSliderLoading: false,
    createSliderError: null,
    editSlider: null,
    updateSliderJson: null,
    updateSliderLoading: false,
    updateSliderError: null,
    deleteSlidersImages: null,
    deleteSlidersImagesLoading: false,
    deleteSlidersImagesError: null,
  },

  reducers: {
    clearSliderErrors: (state) => {
      state.createSliderError = null;
      state.updateSliderError = null;
    },
    clearSliderResponse: (state) => {
      state.addSlider = null;
      state.updateSliderJson = null;
    },
    editSlider: (state, action) => {
      state.editSlider = action.payload;
    },
  },

  extraReducers: {
    [createSlider.pending]: (state) => {
      state.createSliderLoading = true;
    },
    [createSlider.fulfilled]: (state, action) => {
      state.addSlider = action.payload;
      state.createSliderLoading = false;
      state.createSliderError = null;
    },
    [createSlider.rejected]: (state, action) => {
      state.createSliderError =
        action.payload || "Échec de la création du slider";
      state.createSliderLoading = false;
    },
    [getAllSliders.pending]: (state) => {
      state.getAllSlidersImagesLoading = true;
    },
    [getAllSliders.fulfilled]: (state, action) => {
      state.getAllSlidersImages = action.payload;
      state.getAllSlidersImagesLoading = false;
      state.getAllSlidersImagesError = null;
    },
    [getAllSliders.rejected]: (state, action) => {
      state.getAllSlidersImagesError =
        action.payload || "Échec de la récupérer des données du sliders";
      state.getAllSlidersImagesLoading = false;
    },
    [updateSlider.pending]: (state) => {
      state.loading = true;
    },
    [updateSlider.fulfilled]: (state, action) => {
      state.updateSliderJson = action.payload;
      state.updateSliderLoading = false;
      state.updateSliderError = null;
    },
    [updateSlider.rejected]: (state, action) => {
      state.updateSliderError =
        action.payload || "Échec de Mise à jours du slider";
      state.updateSliderLoading = false;
    },
    [deleteSliderImageById.pending]: (state) => {
      state.deleteSlidersImagesLoading = true;
    },
    [deleteSliderImageById.fulfilled]: (state, action) => {
      state.getAllSlidersImages = action.payload;
      state.deleteSlidersImagesLoading = false;
      state.deleteSlidersImagesError = null;
    },
    [deleteSliderImageById.rejected]: (state, action) => {
      state.deleteSlidersImagesError =
        action.payload || "Échec de la suppression du slider";
      state.deleteSlidersImagesLoading = false;
    },
  },
});

export default SliderSlice.reducer;
export const { clearSliderErrors, clearSliderResponse, editSlider } =
  SliderSlice.actions;
